import logo from '@/assets/images/logo/logo_img.png'

let platformConfig = {}
// 測試環境
const beforeOnlineConfig = {
  logo,
  appId: 'bugaboom',
  locale: {
    tw: '繁體中文',
    en: 'English'
  },
  title: 'BugaBoom Admin Dashboard',
  baseURL: 'https://devback.bugaboomsf.com/gateway/admin',
  // uploadUrl: 'https://dev.coinfare.net/gateway/common/upload/file',
  // forgetPasswordUrl: 'https://dev.coinfare.net/gateway/common/user/forgetPassword',
  // phoneCountryUrl: 'https://dev.coinfare.net/gateway/common/query/country',
  // userCountryUrl: 'https://dev.coinfare.net/gateway/common/query/country/type'
}
// 正式環境
const bugaboomConfig = {
  logo,
  appId: 'bugaboom',
  locale: {
    tw: '繁體中文',
    en: 'English'
  },
  title: 'BugaBoom Admin Dashboard',
  baseURL: 'https://back.bugaboomsf.com/gateway/admin',
  // uploadUrl: 'https://dev.coinfare.net/gateway/common/upload/file',
  // forgetPasswordUrl: 'https://dev.coinfare.net/gateway/common/user/forgetPassword',
  // phoneCountryUrl: 'https://dev.coinfare.net/gateway/common/query/country',
  // userCountryUrl: 'https://dev.coinfare.net/gateway/common/query/country/type'
}
switch (process.env.VUE_APP_TITLE) {
  case 'bugaboom': // 怪獸正式
    platformConfig = bugaboomConfig
    break
  case 'beforeOnline': // 怪獸測試
    platformConfig = beforeOnlineConfig
    break
  default:
    platformConfig = beforeOnlineConfig
}
export default platformConfig
