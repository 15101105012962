import platformConfig from '@/config/PlatformConfig'
const isProduction = process.env.NODE_ENV !== 'development'

const config = {
  options: {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
  },
  baseURL: '',
  routerBase: ''
}

// 要開始打api的話，這裡一定要換
switch (process.env.VUE_APP_TITLE) {
  case ('beforeOnline'): // 怪獸_測試
  case ('bugaboom'): // 怪獸_正式
    config.baseURL = `${window.location.protocol}//${window.location.host}/gateway/admin`
    // config.uploadUrl = `${window.location.protocol}//${window.location.host}/gateway/common/upload/file`
    // config.forgetPasswordUrl = `${window.location.protocol}//${window.location.host}/gateway/common/user/forgetPassword`
    // config.phoneCountryUrl = `${window.location.protocol}//${window.location.host}/gateway/common/query/country`
    // config.userCountryUrl = `${window.location.protocol}//${window.location.host}/gateway/common/query/country/type`
    break
  default:
    config.baseURL = platformConfig.baseURL
    config.uploadUrl = platformConfig.uploadUrl
    config.forgetPasswordUrl = platformConfig.forgetPasswordUrl
    config.phoneCountryUrl = platformConfig.phoneCountryUrl
    config.userCountryUrl = platformConfig.userCountryUrl
}
// 要開始打api的話，這裡一定要換

export { config }
