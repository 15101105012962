import * as XLSX from 'xlsx'

export function outputPlatformData (dataList, sheetName = 'output') {
  const headers = dataList.map(item => item.key);
  const values = dataList.map(item => item.value);
  const ws = XLSX.utils.aoa_to_sheet([headers, values]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `${sheetName}.xlsx`);
}

export function changeRange (data) {
  data = JSON.parse(JSON.stringify(data)) // 解除參數的雙向綁定，並重設excel撈取的範圍
  data.page = 1
  data.size = 99999
  return data
}

/**
 * 生成匯出Excel的資料(一組table)
 * @param {array} data  table資料
 * @param {array} columns  column欄位設定
 * @param {string} fileName  excel檔名
 * @param {boolean} handleBgColors  是否有背景色
 */
export function createExcelData (data, columns, fileName, { handleBgColors = false } = {}) {
  const excelContents = data.map(item => {
    const obj = {}
    columns.forEach(e => {
      if (typeof e.getValue === 'function') {
        obj[e.title] = e.getValue({ record: item})
      } else {
        obj[e.title] = item[e.dataIndex]
      }
    })
    return obj
  })
  const excelData = {
    fileName: `${fileName}.xlsx`,
    content: [
      { sheetName: fileName, contents: excelContents }
    ]
  }
  this.excelFileOutput(excelData, handleBgColors)
}

// 生成匯出Excel的資料(多組table、column)
export function createExcelDataByMultiple (dataColumns, fileName) {
  const sheetContents = []
  dataColumns.forEach(dataColumn => {
    const excelContents = dataColumn.data.map(item => {
      const obj = {}
      dataColumn.column.forEach(e => {
        if (typeof e.getValue === 'function') {
          obj[e.title] = e.getValue({ record: item})
        } else {
          obj[e.title] = item[e.dataIndex]
        }
      })
      return obj
    })
    sheetContents.push(
      {
        contents: excelContents,
        sheetName: dataColumn.sheetName
      }
    )
  })
  const excelData = {
    fileName: `${fileName}.xlsx`,
    content: sheetContents
  }
  this.excelFileOutput(excelData)
}

// 匯出excel檔案
export function excelFileOutput (excelData, handleBgColors) {
  const totalContent = excelData.content
  const wb = XLSX.utils.book_new()
  const wbName = excelData.fileName

  for (const item in totalContent) {
    const sheetContent = XLSX.utils.json_to_sheet(totalContent[item].contents)
    const sheetName = totalContent[item].sheetName
    XLSX.utils.book_append_sheet(wb, sheetContent, sheetName)

    // 處理背景色
    if (handleBgColors) {
      const reg = /^#([0-9a-f]{3}){1,2}$/i

      Object.keys(sheetContent).forEach(key => {
        if (reg.test(sheetContent[key].v)) { // value符合 色碼標準
          wb.Sheets[sheetName][key].s = {
            fill: {
              patternType: 'solid',
              fgColor: { rgb: (sheetContent[key].v).replace('#', '') }
            }
          }
          sheetContent[key].v = ''
        }
      })
    }
  }

  XLSX.writeFile(wb, wbName)
}