import { createApp } from 'vue'
import { nextTick } from '@vue/runtime-core'
import { router } from './plugins/router'
import { message } from 'ant-design-vue'
import i18n from './plugins/i18n'
import ant from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import { api } from '@/util/api'
import { notice } from '@/util/notice'
import * as number from '@/util/number'
import * as excel from '@/util/excel'
import * as date from '@/util/date'
import * as list from '@/util/list'
// QuillEditor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const isPhone = navigator.userAgent.includes('Mobile')

const app = createApp(App)

nextTick(() => {
  app.config.globalProperties.$isPhone = isPhone
})

// 註冊全域變數
app.provide('$api', api)
app.provide('$list', list)
app.provide('$t', i18n.global.t)
app.provide('$notice', notice)
app.provide('$number', number)
app.provide('$excel', excel)
app.provide('$date', date)
app.provide('$message', message)

// 註冊元件
app.component('QuillEditor', QuillEditor)

app.use(router)
  .use(i18n)
  .use(ant)
  .mount('#app')
