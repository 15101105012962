import i18n from '@/plugins/i18n'
import { api } from '@/util/api'

const aboutSubMenu = [
  { label: 'history', path: '/history' },
  { label: 'ourMission', path: '/mission' },
  { label: 'contactUs', path: '/contact' }
]

export const menuList = [
  { label: 'aboutUs', path: '/history', children: aboutSubMenu },
  { label: 'whatwedo', path: '/ecotour' },
  // { label: 'newsMedia', path: 'latestNews' }
  { label: 'newsMedia', path: 'latestNews' } // 先拿掉下拉選單
]

export const localeList = [
  {
    value: 'en-US',
    label: 'En'
  },
  {
    value: 'zh-TW',
    label: '繁中'
  }
]

export const shelfStatusList = [
  { label: i18n.global.t('upShelf'), value: 'upShelf' },
  { label: i18n.global.t('downShelf'), value: 'downShelf' }
]

export const statusList = [
  { label: i18n.global.t('status_enable'), value: 'enable' },
  { label: i18n.global.t('status_disable'), value: 'disable' }
]
// 語言列表
export const getLangList = async () => {
  try {
    const { data } = await api.get('language')
    return data.data.map(({key, label}) => ({value: key, label}))
  } catch (err) {
    console.log('error', err)
  }
}
// 接收者類型
export const receiverTypeList = [
  { label: i18n.global.t('receiverType_current'), value: 'current' },
  { label: i18n.global.t('receiverType_new'), value: 'new' },
  { label: i18n.global.t('receiverType_uid'), value: 'uid' },
]
