import { createRouter, createWebHashHistory } from 'vue-router'

export const mainRoutes = {
  name: 'Main',
  component: () => import(/* webpackChunkName: "Main" */ '@/views/Main'),
  children: [
    // 首頁
    { path: '/platform-data', component: () => import(/* webpackChunkName: "PlatformData" */ '@/views/Home/PlatformData'), name: 'index' },
    // 系統管理
    { path: '/user-center', component: () => import(/* webpackChunkName: "UserCenter" */ '@/views/User/UserCenter'), name: 'user-center' },
    // 會員管理
    { path: '/member-list', component: () => import(/* webpackChunkName: "MemberList" */ '@/views/Member/MemberList'), name: 'member-list' },
    { path: '/member-deposit', component: () => import(/* webpackChunkName: "MemberDeposit" */ '@/views/Member/MemberDeposit'), name: 'member-deposit' },
    // 用戶管理
    { path: '/staff-list', component: () => import(/* webpackChunkName: "StaffList" */ '@/views/Staff/StaffList'), name: 'staff-list' },
    { path: '/staff-template', component: () => import(/* webpackChunkName: "StaffTemplate" */ '@/views/Staff/StaffTemplate'), name: 'staff-template' },
    // 財務管理
    { path: '/finance-in-App-purchase-record', component: () => import(/* webpackChunkName: "InAppPurchaseHistory" */ '@/views/Finance/InAppPurchaseHistory'), name: 'finance-in-App-purchase-record' },
    { path: '/finance-shop-record', component: () => import(/* webpackChunkName: "StoreRecords" */ '@/views/Finance/StoreRecords'), name: 'finance-shop-record' },
    // 系統管理
    { path: '/system-maintenance', component: () => import(/* webpackChunkName: "SystemMaintenance" */ '@/views/System/SystemMaintenance'), name: 'system-maintenance' },
    { path: '/system-app-version', component: () => import(/* webpackChunkName: "VersionControl" */ '@/views/System/VersionControl'), name: 'system-app-version' },
    { path: '/system-internal-mail', component: () => import(/* webpackChunkName: "BulkEmailSender" */ '@/views/System/BulkEmailSender'), name: 'system-internal-mail' },
    { path: '/system-award', component: () => import(/* webpackChunkName: "RewardConfigTemplate" */ '@/views/System/RewardConfigTemplate'), name: 'system-award' },
    { path: '/system-config', component: () => import(/* webpackChunkName: "SystemConfig" */ '@/views/System/SystemConfig'), name: 'system-config' },
    // 遊戲資料管理
    { path: '/game-mail-record', component: () => import(/* webpackChunkName: "EmailHistory" */ '@/views/Game/EmailHistory'), name: 'game-mail-record' },
    // 商城管理
    { path: '/shop-diamond', component: () => import(/* webpackChunkName: "ShopDiamond" */ '@/views/Shop/ShopDiamond'), name: 'shop-diamond' },
    { path: '/shop-gold', component: () => import(/* webpackChunkName: "ShopGold" */ '@/views/Shop/ShopGold'), name: 'shop-gold' },
    { path: '/shop-daily', component: () => import(/* webpackChunkName: "ShopDaily" */ '@/views/Shop/ShopDaily'), name: 'shop-daily' },
    { path: '/shop-item', component: () => import(/* webpackChunkName: "ShopItem" */ '@/views/Shop/ShopItem'), name: 'shop-item' },
    { path: '/shop-paid', component: () => import(/* webpackChunkName: "ShopPaid" */ '@/views/Shop/ShopPaid'), name: 'shop-paid' },
    { path: '/shop-special', component: () => import(/* webpackChunkName: "ShopSpecial" */ '@/views/Shop/ShopSpecial'), name: 'shop-special' },
    { path: '/shop-gacha', component: () => import(/* webpackChunkName: "ShopGacha" */ '@/views/Shop/ShopGacha'), name: 'shop-gacha' },
    { path: '/shop-gift-pack', component: () => import(/* webpackChunkName: "ShopGiftPack" */ '@/views/Shop/ShopGiftPack'), name: 'shop-gift-pack' },
    // 活動管理
    { path: '/event-announcement', component: () => import(/* webpackChunkName: "EventAnnouncement" */ '@/views/Event/EventAnnouncement'), name: 'event-announcement' },
    { path: '/notice-announcement', component: () => import(/* webpackChunkName: "NoticeAnnouncement" */ '@/views/Event/NoticeAnnouncement'), name: 'notice-announcement' },
    // 公告管理
    { path: '/announcement-list', component: () => import(/* webpackChunkName: "AnnouncementList" */ '@/views/Announcement/AnnouncementList'), name: 'announcement-list' },
  ],
  meta: {
    requiresAuth: true
  }
}

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
  },
  mainRoutes
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Middleware
router.beforeEach((to, from, next) => {
  const isAuthenticated = sessionStorage.getItem('token')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})
