export function strip (num, precision = 12) {
    return +parseFloat(num.toPrecision(precision))
  }
  
  export function formatNum (num) {
    // 0也要能留下來，所以只擋undefined
    if (num !== undefined) {
      num = Math.round(num * 100) / 100 // 四捨五入取小數點第二位
      const integer = num.toString().split('.')[0]
      const decimal = num.toString().split('.')[1] ? `.${num.toString().split('.')[1]}` : ''
      return `${integer.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${decimal}`
    } else {
      return ''
    }
  }
  
  /**
   * 將Number指定位數四捨五入後改為用千分位顯示之字串
   * dotValue為欲保留的小數點點位，為10的N次方 默認為100
   * 用法:
   * 填入1000即保留小數點後三位。第四位做四捨五入
   */
  export function numberPackage (value, dotValue) {
    let setDot = 100
    if (dotValue !== undefined) {
      setDot = dotValue
    }
    const round = Math.round(value * setDot) / setDot
    const integer = round.toString().split('.')[0]
    const decimal = round.toString().split('.')[1] ? `.${round.toString().split('.')[1]}` : ''
    return `${integer.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${decimal}`
    // return String(round).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  
  // 四捨五入到第二位
  export function round (num, decimal = 2) {
    const decimals = Math.pow(10, decimal)
    return Math.round(num * decimals) / decimals
  }
  
  // 無條件捨去(預設到第二位)
  export function floor (num, decimal = 2) {
    const decimals = Math.pow(10, decimal)
    return Math.floor(num * decimals) / decimals
  }
  